import React, { useCallback } from 'react';
import { CheckBox } from 'ui';
import map from 'lodash/map';
import { useCSSAnim } from 'components/follow-circle';

// import closeIcon from 'assets/icons/close.svg';

export const CookieNotice = ({ setContext, context }) => {
  const { noticed, form } = context;

  const handleOnChange = ({ target: { name, checked } }) => {
    if (name === 'necessary') return;
    setContext({
      noticed,
      form: { ...form, [name]: { ...form[name], value: checked } },
    });
  };

  const selected = useCallback(
    Object.keys(form).filter((key) => form[key].value),
    [form]
  );

  const handleMany = (bool) => () => {
    const newState = form;
    for (const key of Object.keys(newState)) {
      if (key === 'necessary') continue;
      newState[key].value = bool;
    }

    setContext({ noticed: true, form: newState });
  };

  if (noticed) return null;
  return (
    <div className="cookie-notice__container rowm justify-center flex-align-center">
      <div className="cookie-notice">
        {/* <div className="cookie-notice__close">
          <img src={closeIcon} alt="close icon" />
        </div> */}
        <div className="form-group">
          <h2>Datenschutz</h2>
        </div>
        <div className="form-group">
          <p>
            Wir, Synelution GmbH, würden gerne mit externen Diensten
            personenbezogene Daten verarbeiten. Dies ist für die Nutzung der Website
            nicht notwendig, ermöglicht uns aber eine noch engere Interaktion mit
            Ihnen. Falls gewünscht, treffen Sie bitte eine Auswahl:
          </p>
        </div>
        {map(form, ({ value, label, desc }, key) => (
          <div
            key={key}
            className="form-group checkbox-container rowm flex-align-center justify-space-between"
          >
            <div>
              <div className="h4">{label}</div>
              <p>{desc}</p>
            </div>
            <CheckBox checked={value} onChange={handleOnChange} name={key} />
          </div>
        ))}

        <div className="form-group btn-container rowm justify-space-between">
          <Button onClick={handleMany(false)} className="red">
            Nichts Akzeptieren
          </Button>
          <Button
            onClick={() => setContext({ noticed: true, form })}
            disabled={selected.length === 0}
          >
            Auswahl Akzeptieren
          </Button>
          <Button onClick={handleMany(true)}>Alles Akzeptieren</Button>
        </div>
      </div>
    </div>
  );
};

const Button = ({ className, ...props }) => {
  const { handleMouseEnter, handleMouseLeave, handleClick } = useCSSAnim();

  return (
    <button
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      {...props}
      onClick={() => {
        if (typeof props.onClick === 'function') props.onClick();
        handleClick();
      }}
      className={`btn ${className}`}
    >
      {props.children}
    </button>
  );
};
