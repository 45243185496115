import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'intersection-observer';
import 'objectFitPolyfill/dist/objectFitPolyfill.min.js';
// import 'core-js/features/array/fill';
// import 'core-js/features/reflect/apply';

import React from 'react';
import ReactDOM from 'react-dom';
import './scss/bootstrap-grid.min.css';
import './scss/index.min.css';
import * as serviceWorker from './serviceWorker';

import { isIE } from './helper';

import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import reduxThunk from 'redux-thunk';
import { BrowserRouter } from 'react-router-dom';

import { rootReducer as reducer } from './redux';
import MainRouter from './router';
import ScrollRestoration from './router/scrollRestoration';

import { StaticDataProvider } from 'config/staticData';

// const reduxDevTools =
//   window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__();
const composeReduxMiddlewares = () => {
  // if (process.env.NODE_ENV === 'development') {
  //   return compose(applyMiddleware(reduxThunk), reduxDevTools);
  // } else {
  return compose(applyMiddleware(reduxThunk));
  // }
};

const store = createStore(reducer, composeReduxMiddlewares());

if (isIE()) {
  document.body.classList.add('is-ie');
}

ReactDOM.render(
  <Provider store={store}>
    <StaticDataProvider>
      <BrowserRouter>
        <ScrollRestoration>
          <MainRouter />
        </ScrollRestoration>
      </BrowserRouter>
    </StaticDataProvider>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
const isSafari = !!navigator.userAgent.match(/Version\/[\d.]+.*Safari/);
if (isSafari) serviceWorker.unregister();
else serviceWorker.register();
