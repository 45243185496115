import React from 'react';
import { useCSSAnim } from '../follow-circle';
import { useCookieContext } from '../../router/cookie-notice/CookieContext';

const GoogleBadge = () => {
  const { handleMouseEnter, handleMouseLeave, handleClick } = useCSSAnim();
  const { noticed, form } = useCookieContext();

  if (!noticed || !form?.necessary?.value) return null;
  return (
    <>
      <a
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={handleClick}
        className="google-badge"
        href="https://www.google.com/partners/agency?id=9025291068"
        target="_blank"
        rel="noreferrer noopener"
      >
        <img
          src="https://www.gstatic.com/partners/badge/images/2021/PartnerBadgeClickable.svg"
          alt="Google Partner Badge"
        />
      </a>
    </>
  );
};

export default GoogleBadge;
