import React, { Component, Suspense } from 'react';
import { Switch, Route } from 'react-router-dom';

import { withGA } from 'components/utility';
// import { Footer } from './footer';
// import { NavBar } from './nav';
import { Loader, LoaderContextProvider } from './loader';
import { CookieContextProvider } from './cookie-notice/CookieContext';

import { FollowCircleProvider } from '../components/follow-circle';
import GoogleBadge from '../components/GoogleBadge';
const NavBar = React.lazy(() => import('./nav'));
const Footer = React.lazy(() => import('./footer'));
const Kontakt = React.lazy(() => import('../pages/Kontakt'));
const Home = React.lazy(() => import('../pages/Home'));
const Leistungen = React.lazy(() => import('../pages/Leistung'));
const Error404 = React.lazy(() => import('../pages/404'));
const Legal = React.lazy(() => import('../pages/Legal'));
const Werbeagentur = React.lazy(() => import('../pages/Werbeagentur'));
const Eventagentur = React.lazy(() => import('../pages/Eventagentur'));
const Bewerbung = React.lazy(() => import('../pages/Bewerbung'));
const SingleBewerbung = React.lazy(() =>
  import('../pages/Bewerbung/SingleBewerbung')
);

export default class MainRouter extends Component {
  componentDidMount() {
    this.handleScrollbarWiggle();
  }

  handleScrollbarWiggle = () => {
    const isChrome =
      /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
    const isSafari =
      /Safari/.test(navigator.userAgent) && /Apple Computer/.test(navigator.vendor);

    if (!isChrome && !isSafari) {
      document.body.classList.add('no-webkit');
    }
  };

  render() {
    return (
      <LoaderContextProvider>
        <FollowCircleProvider>
          <Loader />
          <Suspense fallback={null}>
            <NavBar />
          </Suspense>
          <CookieContextProvider>
            <GoogleBadge />
            <Suspense fallback={null}>
              <div className="main-content">
                <Switch>
                  <Route
                    exact
                    path="/leistungen/:slug"
                    component={withGA(Leistungen)}
                  />
                  <Route exact path="/kontakt" component={withGA(Kontakt)} />
                  <Route
                    exact
                    path="/werbeagentur"
                    component={withGA(Werbeagentur)}
                  />
                  <Route
                    exact
                    path="/eventagentur"
                    component={withGA(Eventagentur)}
                  />
                  <Route exact path="/bewerbung" component={withGA(Bewerbung)} />
                  <Route
                    exact
                    path="/bewerbung/:slug"
                    component={withGA(SingleBewerbung)}
                  />
                  <Route exact path="/legal/:slug" component={withGA(Legal)} />
                  <Route exact path="/" component={withGA(Home)} />
                  <Route component={withGA(Error404)} />
                </Switch>
              </div>
            </Suspense>
          </CookieContextProvider>

          <Suspense fallback={null}>
            <Footer />
          </Suspense>
        </FollowCircleProvider>
      </LoaderContextProvider>
    );
  }
}
