export const initialState = {
  form: {
    necessary: {
      label: 'Notwendig',
      desc: 'Diese Cookies sind für den Betrieb der Website notwendig.',
      value: true,
    },
    googleAnalytics: {
      label: 'Google Analytics',
      desc: 'Google LLC, USA',
      value: false,
    },
    mapBox: {
      label: 'Mapbox',
      desc: 'Interaktive Karte',
      value: false,
    },
    facebookPixel: {
      label: 'Facebook Pixel',
      desc: 'Facebook Pixel, Irland, USA',
      value: false,
    },
  },
  noticed: false,
};
